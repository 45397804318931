.applyContainer {
    width: 100%;
    margin: 0 0 12px;
    padding: 16px 140px 23.3px;
    background-color: #fff;
}

div.info {
    text-align: left;
    padding-bottom: 16px;
}

div.info div.title {
    font-size: large;
    font-weight: bold;
    color: #707070;
}

span.label {
    font-weight: bold;
    color: #707070;
}

span.finePrint {
    color: #949393;
    font-size: 10pt;
}

div.barOutContainer {
    background-color: #f2f2f2;
    padding: 50px;
}

div.barInnerContainer {
    margin-left: 50px;
    margin-right: 70px;
}

div.barLabelContainer {
    padding-top: 5px;
    color: #808080;
    font-weight: bold;
}

div.barLabelContainer table {
    width: 100%;
}

div.barLabelContainer div.label1 {
    text-align: left;
    width: 33%;
    margin-left: -50px;
}

div.barLabelContainer div.label2 {
    text-align: center;
    width: 34%;
    margin-left: 25px;
}

div.barLabelContainer div.label3 {
    text-align: right;
    width: 33%;
    margin-left: 50px;
}

div.buttonContainer {
    width: 100%;
    margin: 0 0 12px;
    padding: 16px 140px 23.3px;
    background-color: #fff;
    text-align: right;
}

div.captchaContainer {
    width: 100%;
    margin-top: -30px;
    padding-right: 140px;
    background-color: #fff;
    text-align: right;
}

div.captchaContainer.show {
    display: block;
}

div.captchaContainer.hide {
    display: none;
}

div.stepinfo {
    padding: 20px 50px 10px 50px;
    text-align: justify;
    text-justify: inter-word;
}

div.label {
    padding: 5px 0px 5px 50px;
    margin-top: 20px;
    font-weight: bold;
    background-color: #f2f2f2;
}

div.file {
    padding: 10px 0px 10px 50px;
}

input.hidden {
    display: none;
}

div.labelRow {
    display: flex;
    flex-Direction: row;
    padding-left: 50px;
    margin-top: 10px;
}

div.inputRow, div.radioRow {
    display: flex;
    flex-Direction: row;
    padding-left: 50px;
    margin-bottom: 10px;
}

div.inputRow input, div.inputRow select {
    width: 90%;
}

div.inputRow textarea {
    width: 95%;
}

div.radioRow span {
    padding-left: 10px;
    padding-right: 10px;
}

div.radioCell {
    width: 30%;
    margin-top: 10px;
}

.applyLink {
    font-weight: normal;
    cursor: pointer;
    color: #7371FC;
    text-decoration: none;
}

div.list {
    display: flex;
    flex-Direction: row;
    padding-left: 50px;    
}

.form-check-label {
    padding-left: 10px;
}