
.progressBar {
    height: 10px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgba(211, 211, 211, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
}
.progression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: rgba(0, 116, 217, 0.8);
  z-index: -1;
}

.progressBar .step {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.indexedStep {
    color: white;
    width: 30px;
    height: 30px;
    font-size: 16px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indexedStep.ready {
    background-color: rgba(0, 116, 217, 1);
  }

  .indexedStep.done {
    background-color: green;
  }

  