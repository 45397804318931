div.search {
    display: flex;
    margin-bottom: 26px;
}

div.search .hint {
    padding-left: 10px;
    color: #949494;
}

div.search input {
    width: 80%;
    border-radius: 10px;
    border: 1px solid grey;
}

div.search .button {
    padding-top: 16px;
    text-align: left;
}

div.search .button button {
    background-color: #7F1621;
    border-color: #7F1621;
}

.openings {
    border-bottom: 2px solid #7371FC;
}

.openings thead th {
    border-top: 2px solid #7371FC;
    border-bottom: 2px solid #7371FC;
    padding-top: 10px;
    padding-bottom: 10px;
}

.openings td {
    padding-top: 10px;
    padding-bottom: 10px;
}
.openings tr:nth-child(odd) td {
    background-color: #F2F2F2;
}

.openings .link {
    text-decoration: none;
    color: inherit;
}

div.outContainer {
    padding: 40px 140px 40px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

div.inContainer {
    padding: 10px 0px 10px;
}

div.desc {
    margin-bottom: 50px;
}

div.desc span span {
    margin-left: 40px;
}

div.desc span u {
    text-decoration: none;
}

div.button {
    margin-left:auto;
}

div.button a {
    background-color: #7F1621;
    border-color: #7F1621;
}

.openings_body {
    max-width: 1560px;
    margin: auto;
}