.Header{
    background-color: white; 
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, .1); 
    min-height: 95px;
    max-height: 1000px;
}

.logo{
    position: absolute; 
    width: 150px; 
    height: 78.95px;
    top: 7px; 
    z-index: 1; 
    left: 20px; 
    padding-bottom: 10px;
}

.everything{
	max-width: 1560px;
}

@media screen and (min-width: 900px) {
	.everything{
		margin: auto;
		padding: 0px 100px;
	}
}



.rectangle2{
        width: 100%;
        background-color: #58040B;
}

.rectanglefooter{
        width: 100%;
        background-color: black;
        position: relative; 
        bottom: 0px;
}

.footer{
    color: white; 
    max-width: 1750px; 
    margin: auto;
}

.links{
    border-right: solid thin white; 
    font-size: 12px;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px !important;
}

.link1{
    color: white;
}

.footerm {
    padding-left: 40px ; 
    padding-top: 10px; 
    position: relative;
}

.officetitle {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
}

.address {
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
    position: relative;
    font-size: 12px;
}

#contact {
    border-left: solid thin white; 
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
}

.number {
    font-size: 12px;
}

.contactlink {
    color: white;
}

.copywrite {
    color: darkgray; 
    width: 300px; 
    position: absolute; 
    left: 20px; 
    bottom: 1px; 
    font-size: 12px
}

.follow {
    color: darkgray; 
    width: 150px; 
    position: absolute; 
    right: 15px; 
    bottom: 1px; 
    font-size: 12px
}

.fb {
    position: absolute; 
    right: 5px; 
    height: 50px; 
    bottom: 0px
}

.lkn {
    position: absolute; 
    right: 40px; 
    height: 50px; 
    bottom: 0px
}

.icon {
    font-size: 20px; 
    color: white
}

.font12 {
    font-size: 12px; 
}

.s1 {
    max-width: 2000px; 
    margin: auto;
}

.s2 {
    background-color: #58040B; 
    position: relative; 
    top: 10px;
}
.s3 {
    font-size: 30px; 
    color: white;
}
.s4 {
    position: relative; 
    top: 10px; 
    font-size: 15px; 
    left: 0; 
    padding: 20px 0px; 
    width: 100%;
}