.App {
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Image-1 {
  width: 14%;
  position: relative;
  top: 30%
}

.Rectangle-1 {
  width: 100%;
  height: auto;
  margin: 0 0 12px;
  padding: 16px 140px 23.3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.layoutContainer {
  padding: 16px 140px 23.3px;
  flex-grow: 1;
}

.nav-link {
  color: #A7A7A7;
  letter-spacing: 2px;
  margin-right: 31px;
}

.nav-link:hover {
  color: default;
}